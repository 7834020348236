<template>
  <div class="mt-4">
    <v-simple-table large>
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-center table-header">
            #
          </th>
          <th class="text-center table-header">
            عنوان
          </th>
          <th class="text-center table-header">
            مالک
          </th>
        
          <th class="text-center table-header">
            قیمت کل / رهن :
          </th>

          <th class="text-center table-header">
            قیمت متری / اجاره :
          </th>

          <th class="text-center table-header">
            شماره طبقه :
          </th>

          <th class="text-center table-header">
            ناریخ
          </th>

          <th class="text-center table-header">
            عملیات
          </th>
        </tr>
        </thead>

        <tbody class="table-body">
        <slot/>
        </tbody>


      </template>

    </v-simple-table>
  </div>
</template>

<script>


export default {
  name: "FileList",

  props: {
    items: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },

  methods: {}


}
</script>

<style scoped>
</style>